const INFLOW_SOURCE_PARAM = '_src';
const LINE_PARAM = '_line';
const LINE_SESSION_KEY_PARAM = '_lineSessionKey';
const WIDGET_PARAM = 'widget';

export function margeParams(
  baseUrlOrPathname: string,
  overrideParams?: {
    line?: string;
    lineSessionKey?: string;
    inflowSource?: string;
  }
): string {
  const currentUrl = new URL(document.location.href);
  const url = baseUrlOrPathname.startsWith('/')
    ? new URL(`${currentUrl.origin}${baseUrlOrPathname}`)
    : new URL(baseUrlOrPathname);
  const searchParams = url.searchParams;
  const src =
    overrideParams?.inflowSource ||
    currentUrl.searchParams.get(INFLOW_SOURCE_PARAM);
  if (src) {
    searchParams.set(INFLOW_SOURCE_PARAM, src);
  }
  const line = overrideParams?.line || currentUrl.searchParams.get(LINE_PARAM);
  if (line) {
    searchParams.set(LINE_PARAM, line);
  }
  const lineSessionKey =
    overrideParams?.lineSessionKey ||
    currentUrl.searchParams.get(LINE_SESSION_KEY_PARAM);
  if (lineSessionKey) {
    searchParams.set(LINE_SESSION_KEY_PARAM, lineSessionKey);
  }
  const widget = currentUrl.searchParams.get(WIDGET_PARAM);
  if (widget) {
    searchParams.set(WIDGET_PARAM, widget);
  }
  const normal = currentUrl.searchParams.get('normal');
  if (normal) {
    searchParams.set('normal', normal);
  }
  return url.toString();
}
