import dayjs from 'dayjs';
import { PublicShopSetting } from '../../../@interfaces/shop-setting';
import { PublicWorkspaceSetting } from '../../../@interfaces/workspace-setting';
import {
  AnyField,
  defaultFieldCondition,
  FormResponse,
} from '../../../core/types/reservation-form-types';
import {
  DateFormat,
  ReservationTable as IReservationTable,
  toDateStringByDayjs,
} from '../../../core/types/reservation-types';
import { DEFAULT_PRIMARY_COLOR } from '../../../models/theme';

const defaultStartDate = dayjs();

export const calcInitialStartDate = (
  searchParams: URLSearchParams,
  shopSetting: PublicShopSetting | undefined
): DateFormat => {
  return (searchParams.get('startDate') ||
    (shopSetting &&
      shopSetting.webReservationLimitType !== 'none' &&
      shopSetting.webReservationLimitDays &&
      toDateStringByDayjs(
        dayjs().add(shopSetting.webReservationLimitDays, 'days')
      )) ||
    toDateStringByDayjs(defaultStartDate)) as DateFormat;
};

export const calcInitialEndDate = (
  searchParams: URLSearchParams,
  widgetMode: boolean,
  normalMode: boolean,
  startDateParam: DateFormat
): DateFormat => {
  if (
    widgetMode &&
    !normalMode &&
    searchParams.get('startDate') === searchParams.get('endDate')
  ) {
    return startDateParam;
  }
  return (searchParams.get('endDate') ||
    toDateStringByDayjs(dayjs(startDateParam).add(6, 'days'))) as DateFormat;
};

export const getPrimaryColor = (
  workspaceSetting: PublicWorkspaceSetting | undefined,
  shopSetting: PublicShopSetting | undefined
) => {
  return (
    shopSetting?.primaryColor ||
    workspaceSetting?.primaryColor ||
    DEFAULT_PRIMARY_COLOR
  );
};

// 予約可能な日付のリストを取得
export const getWeeklyReservationsLength = (
  reservationTable: IReservationTable
) =>
  reservationTable.dates.filter((elem) => {
    const isExistSlots = elem.slots.length > 0;
    const isVacancyReserve =
      elem.slots.filter((slot) => {
        return slot.capacity.total - slot.reserved.total > 0;
      }).length > 0;

    return isExistSlots && isVacancyReserve;
  }).length;

export const isAnsweredRequiredQuestion = (
  minutesRequiredFields: AnyField[],
  formResponse: FormResponse
) => {
  const requiredFields = minutesRequiredFields.filter(
    (field) => (field.condition || defaultFieldCondition).public.required
  );
  const requiredFieldsUids = requiredFields.map((field) => field.uid);
  const answeredFieldsUids = formResponse.fields
    .filter((field) => field.values.length > 0)
    .map((field) => field.uid);

  const matchAnswered = requiredFieldsUids
    .concat(answeredFieldsUids)
    .filter(function (x, i, self) {
      return self.indexOf(x) === i && i !== self.lastIndexOf(x);
    });

  return matchAnswered.length >= requiredFieldsUids.length;
};
