type EmptyReservationTableProps = {
  widgetMode: boolean;
  normalMode: boolean;
};

/**
 * 予約枠がない場合の表示
 */
export const EmptyReservationTable = ({
  widgetMode,
  normalMode,
}: EmptyReservationTableProps): JSX.Element => {
  return (
    <p
      style={{
        textAlign: 'center',
        fontSize: '14px',
        lineHeight: '150%',
        fontWeight: 'bold',
        marginTop: '24px',
      }}
    >
      この{widgetMode && !normalMode ? '日' : '週'}の予約枠はありません。
      <br />
      カレンダーから他の日付を選択してください。
    </p>
  );
};
